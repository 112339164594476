import React, { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';

const SideContainer = ({ children }: PropsWithChildren) => {
  return (
    <Stack height="100%" position="relative">
      {children}
    </Stack>
  );
};

export default SideContainer;
