import Stack from '@mui/material/Stack';

type TwoSidesLayoutProps = {
  children: React.ReactNode;
  rightSide: React.ReactNode;
};

// This is a layout component that takes two children and displays them side by side.
// The left child will be displayed on all screen sizes.
// The right child will be displayed on medium and large screens.
const TwoSidesLayout = ({ children, rightSide }: TwoSidesLayoutProps) => {
  return (
    <Stack height="100dvh" direction="row">
      <Stack flex={1}>{children}</Stack>
      <Stack
        minWidth={420}
        width="35vw"
        display={{
          mobile: 'none',
          signupLayout: 'block',
        }}
      >
        {rightSide}
      </Stack>
    </Stack>
  );
};

export default TwoSidesLayout;
