import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const NeedHelp = () => (
  <Typography variant="bodySmallLight">
    Need help? Email us at <Link href="mailto:hello@ghst.io">hello@ghst.io</Link>
  </Typography>
);

export default NeedHelp;
