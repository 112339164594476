'use client';

import { useEffect, useRef } from 'react';
import { useFormState } from 'react-dom';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import MuiLnk from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { redirect, useSearchParams } from 'next/navigation';
import { getBaseURL } from '@/app/actions';
import Link from '@/components/Link';
import {
  Content,
  Footer,
  Header,
  ScrollableContent,
  SideContainer,
} from '@/components/TwoSidesLayout';
import { makeAxiosClient } from '@/legacy/common/axiosApiClient';
import { getRedirectPathAfterSignIn } from '@/lib/getRedirectPathAfterSignIn';
import { handleSignIn } from './actions';

export default function Form({
  baseURL: _,
  isReferralEnabled,
  isSellerAnalyticsEnabled,
}: {
  baseURL: string;
  isReferralEnabled: boolean;
  isSellerAnalyticsEnabled: boolean;
}): JSX.Element {
  const [state, formAction] = useFormState(handleSignIn, { valid: false });
  const emailRef = useRef<HTMLInputElement>(null);
  const searchParams = useSearchParams();
  const email = searchParams.get('email');
  const redirectPath = searchParams.get('redirect');

  useEffect(() => {
    if (state?.valid) {
      if (redirectPath) {
        redirect(redirectPath);
      }

      redirect(getRedirectPathAfterSignIn(state.user, isReferralEnabled, isSellerAnalyticsEnabled));
    }
  }, [state?.valid, state?.user, redirectPath, isReferralEnabled, isSellerAnalyticsEnabled]);

  const emailError =
    state?.valid === false && state.errors?.email?.length ? state.errors.email[0] : undefined;
  const passwordError =
    state?.valid === false && state.errors?.password?.length ? state.errors.password[0] : undefined;

  const errorMessage = state?.valid === false && !!state.message ? state.message : undefined;

  const callFormAction = async (payload: FormData) => {
    const baseURL = await getBaseURL();

    // also call this from the client to set the api cookie
    const axiosApiClient = makeAxiosClient(baseURL);

    axiosApiClient
      .post('/auth/sign-in', {
        email: payload.get('email'),
        password: payload.get('password'),
      })
      .then(() => {
        formAction(payload);
      });
  };

  const footerHelperText = email ? (
    <Link href="/auth/forgot-password">Forgot Password</Link>
  ) : (
    <Typography variant="bodySmallLight">
      Don&apos;t have an account? <Link href="/auth/get-started">Create account</Link>
    </Typography>
  );

  const resetAndFocusEmailInput = () => {
    if (!emailRef.current) return;
    emailRef.current.value = '';
    emailRef.current.focus();
  };

  return (
    <form action={callFormAction} style={{ height: '100%' }}>
      <SideContainer>
        <ScrollableContent>
          <Header />
          <Content>
            <Box mb={4}>
              {email ? (
                <>
                  <Typography variant="h3" mb={3}>
                    Welcome back, sign in
                  </Typography>
                  <Typography variant="bodyLargeLight">
                    {`Continue as ${email}. Not you? `}
                    <MuiLnk onClick={resetAndFocusEmailInput}>Choose a different email</MuiLnk>
                  </Typography>
                </>
              ) : (
                <Typography variant="h1" maxWidth="400px">
                  Sign in to Ghost
                </Typography>
              )}
            </Box>
            <Stack spacing={3}>
              <TextField
                label="Your email"
                name="email"
                type="email"
                defaultValue={email}
                error={!!emailError}
                placeholder="Email"
                data-testid="input-emailField"
                inputRef={emailRef}
                helperText={emailError ? <Typography>{emailError}</Typography> : undefined}
              />
              <Box position="relative">
                <TextField
                  label="Your password"
                  name="password"
                  type="password"
                  error={!!passwordError}
                  placeholder="Password"
                  data-testid="input-passwordField"
                  fullWidth
                  helperText={passwordError ? <Typography>{passwordError}</Typography> : undefined}
                />
                {email ? (
                  <></>
                ) : (
                  <Link
                    href="/auth/forgot-password"
                    data-testid="button-forgotPassword"
                    position="absolute"
                    right={0}
                    zIndex={1}
                    variant="bodySmallLight"
                  >
                    Forgot password
                  </Link>
                )}
              </Box>
            </Stack>
            {!!errorMessage && (
              <FormHelperText error sx={{ mt: 1 }}>
                {errorMessage}
              </FormHelperText>
            )}
          </Content>
        </ScrollableContent>
        <Footer onNextText="Sign In" footerHelperText={footerHelperText} />
      </SideContainer>
    </form>
  );
}
