import React from 'react';
import { CaretBack } from '@ghosts-inc/design-system/icons';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SubmitButton from '../SubmitButton';

type FooterProps = {
  footerHelperText?: string | React.ReactNode;
  onNext?: () => void;
  onNextText?: string;
  onPrev?: () => void;
  nextDisabled?: boolean;
};

const Footer = ({ onNext, onNextText, onPrev, footerHelperText, nextDisabled }: FooterProps) => {
  return (
    <Stack
      position="sticky"
      bottom="0"
      borderTop="1px solid"
      borderColor="surface.light"
      bgcolor="white"
      zIndex={1}
      alignItems={{
        mobile: 'center',
        signupLayout: 'flex-start',
      }}
      px={{
        mobile: 2,
        signupLayout: 8,
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        height="100px"
        maxWidth={{
          mobile: 600,
          signupLayout: 'none',
        }}
        width={{
          mobile: '100%',
          signupLayout: '60%',
        }}
        minWidth={{
          mobile: 'unset',
          signupLayout: 600,
        }}
      >
        {footerHelperText ? <Box flex={1}>{footerHelperText}</Box> : null}
        <Stack
          flexDirection="row-reverse"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
        >
          {onNext || onNextText ? (
            <SubmitButton
              variant="contained"
              onClick={onNext}
              data-testid="button-submitNext"
              disabled={nextDisabled}
            >
              {onNextText || 'Next'}
            </SubmitButton>
          ) : (
            <></>
          )}
          {onPrev ? (
            <Stack
              flexDirection="row"
              alignItems="center"
              columnGap={1}
              style={{ cursor: 'pointer' }}
              onClick={onPrev}
            >
              <CaretBack />
              <Typography variant="bodySmallLight">Back</Typography>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
