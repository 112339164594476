import React, { PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';

// This layout component takes one child and displays it in a scrollable container.
// We remove the 'overflow' property when the gradient is not shown to avoid weird scrolling behavior
// on Safari. On mobile, the body needs to be the scrollable container.
const ScrollableContent = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      flex={1}
      overflow={{
        mobile: 'unset',
        signupLayout: 'auto',
      }}
    >
      {children}
    </Stack>
  );
};

export default ScrollableContent;
